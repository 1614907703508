[class*=sidebar-dark-] {
    background-color: theme-color('primary');
}

[class*=sidebar-dark-] .sidebar a,
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link,
[class*=sidebar-dark-] .brand-link {
    color: white;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #386642;
}

[class*="sidebar-dark-"] .nav-treeview>.nav-item>.nav-link.active,
[class*="sidebar-dark-"] .nav-treeview>.nav-item>.nav-link.active:hover,
[class*="sidebar-dark-"] .nav-treeview>.nav-item>.nav-link.active:focus {
    background-color: rgba(255, 255, 255, 0.6);
}