.form-footer {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    background-color: rgba(0, 0, 0, .03);
    border-top: 0 solid rgba(0, 0, 0, .125);
    justify-content: space-between;
    -ms-flex-pack: justify;

    button:only-child {
        margin-left: auto;
    }
}

.toasts-top-right {
    z-index: 1051;
}