// Theme
@import 'theme';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Tempus Dominus
@import '~@eonasdan/tempus-dominus/src/scss/tempus-dominus';

// Bootstrap ColorPicker
@import '~admin-lte/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.css';

// DataTables Bootstrap 4 & Extensions
@import '~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.css';
@import '~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.css';
@import '~admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.css';

// OverlayScrollbars
@import '~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.css';

// Json Editor
@import '~jsoneditor/dist/jsoneditor.css';

// Select2
@import '~admin-lte/plugins/select2/css/select2.css';

// iCheck Material
@import '~icheck-material/icheck-material';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

// Sidebar
@import 'sidebar';

// Modals
@import 'modals';

@import '~loading-visualization/dist/main.css';

.actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0.3rem;
    column-gap: 0.3rem;
}

.preloader {
    background-color: #1f2d3dcc;
}

.brand-text {
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: center;
}

.brand-link {
    display: flex;
    flex-direction: column;
}

.brand-link .brand-image {
    float: unset;
    align-self: center;
    height: 50px;
    max-height: unset;
    max-width: 90%;
    margin: 0;
    border-radius: 0%;
    transition: 0.5s;
    object-fit: cover;
}

.brand-link .brand-image.collapsed {
    border-radius: 50%;
}

// BUG Notify inside modal
paper-toast {
    z-index: 1;
}

.modal {
    overflow-y: auto;
}

.card-footer {
    background-color: initial !important;
}

.card-footer>div.row>div.col.text-center>button {
    border: 1px solid #dee2e6 !important;
}

.datepicker[readonly] {
    background-color: white;
}

.card-primary.card-outline {
    border-top: 3px solid theme-color('primary');
}

.btn-info2 {
    background-color: #8E4802;
    border-color: #8E4802;
    color: white;
}

.badge-info2 {
    background-color: #8E4802;
    color: white;
}

.font-size-2x {
    font-size: 1.2rem;
}

.font-size-2x>.svg-inline--fa {
    font-size: 2rem;
}

.btn-secondary2 {
    background-color: #45617A !important;
    border-color: #45617A !important;
    color: white !important;
}

.card-header .collapse-arrow {
    transition: transform 300ms linear;
}

.card-header:not(.collapsed) .collapse-arrow {
    transform: rotate(180deg);
}