$theme-colors: (
    "primary": #4B8E5B,
);

$regular-weight: 400;
$bold-weight: 700;

$fonts-path: '../fonts';

@font-face {
    font-family: 'Nunito';
    font-weight: $regular-weight;
    font-style: normal;
    src: url('#{$fonts-path}/Nunito-Regular.ttf');
}

@font-face {
    font-family: 'Nunito';
    font-weight: $bold-weight;
    font-style: normal;
    src: url('#{$fonts-path}/Nunito-Bold.ttf');
}

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;